<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="items">
          <div class="item bor">
            <div class="box qq">
              <div class="name">
                  <div class="nbx t">
                    <div class="nbx_1">
                      <span class="bq">申请人：</span>
                      <span class="nr z">{{ username }}</span>                      
                    </div>
                    <div class="nbx_1">
                      <span class="bq">房间编号：</span>
                      <span class="nr z1">{{ roomname }}</span>                    
                    </div>
                    <div class="nbx_1">
                      <span class="bq">住宿时间：</span>
                      <span class="nr z1">{{ sdate }}&nbsp;-&nbsp;{{ edate }}&nbsp;▪&nbsp;{{ num_day }}天</span>                      
                    </div>
                    <div class="nbx_1">
                      <span class="bq">续住时间：</span>
                      <span class="nr z1">{{ edate }}&nbsp;-&nbsp;{{ adddate }}&nbsp;▪&nbsp;{{ num }}天</span>                     
                    </div> 
                  </div>                
              </div>
            </div>                        
          </div>
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="agreecheckin">同意入住</div>    
          <div class="button1 primary mt" @click="cancelcheckin">取消入住</div>     
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
import moment from "moment";

export default {
  created() {
    let openid = get(this.$route, 'query.openid');
    window.sessionStorage.setItem('openid', openid);

    this.adddate = get(this.$route, 'query.adddate');
    this.roomcode = get(this.$route, 'query.roomcode');
    this.tokenstr = get(this.$route, 'query.tokenstr');
    this.username = get(this.$route, 'query.username');

    this.query();
    //this.nowdate = this.getdate();
  },
  /*mounted() {
    this.initCalendar()
  },*/
  data() {
    return {
      sdate: '',
      edate: '',
      adddate: '',
      nowdate: '',
      username: '',
      roomcode: '',
      roomname: '',
      num_day: '',
      num: '',
      tokenstr: '',
      state: false,
      items: []
    };
  },
  methods: {
    /*getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },   
    /*computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },
    initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 36,
          minDate: this.nowdate,
          maxDate: '2020-05-21',
          onSuccess: (data) => {
            this.dateSpace = data.start_date +'~'+ data.end_date;
            this.startdate = data.start_date;
            this.enddate = data.end_date;
            console.log(this.dateSpace);
          },
          onError: function(msg) {
            console.log(msg)
          }
      });
    },*/
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_xzbyjk',
        qs.stringify({ 
          roomcode: this.roomcode, 
          tokenstr: this.tokenstr, 
          adddate: this.adddate
        })
      );
      if (data.flag === '0') {  
        /*this.state = false;
        this.roomname = data.data.room_name;
        this.sdate = data.data.checkin_date;
        this.edate = data.data.checkout_date;
        this.num_day = data.data.num_day;
        this.num = data.data.num_bed;*/

        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 

        this.roomname = testobj.room_name;
        this.sdate = testobj.checkin_date;
        this.edate = testobj.checkout_date;
        this.num_day = testobj.num_day;
        this.num = testobj.num_bed;

      }else {
        //this.state = false;
        //Toast(data.message);
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      } 
    },
    cancelcheckin() {
       Dialog.confirm({
        showCancelButton: false,
        message: "即将退出操作页面！"
      }).then(async () => {
        window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
      });
    },
    async agreecheckin() {      
      if(this.state){
        return false;
      }
      this.state = true;
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_roomaddday_up',
        qs.stringify({ 
          roomcode: this.roomcode, 
          tokenstr: this.tokenstr, 
          adddate: this.adddate, 
          num: this.num, 
          num_day: this.num_day 
        })
      );
      if (data.flag === '0') {  
        this.state = false;
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });       
      }else {
        this.state = false;
        Toast(data.message);
      } 
    
    }
    
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 266px;
}
.content {
  margin-top: 60px;
  height: 410px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}
.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
    &.bor {
      border-bottom: 0px solid #eee;
    }
  }
  .nbox {
    flex: 1;
    overflow: hidden;
    .headline {
      font-size: 26px;
      color: rgb(136, 134, 134);   
      &.h1 {
        margin-right: 30px;
        float: left;
      }
      &.h2 {
        float: right;         
      }
      &.cc {
        color: rgb(255, 255, 255);   
      }
      &.d1 {
        margin-right: 100px;
        margin-left: 30px; 
      }
      &.d2 {
        margin-right: 30px;
      }
      &.d3 {
        margin-left: 10px; 
      }
      &.d5 {
        margin-right: 10px; 
      }
      &.d6 {
        margin-right: 0px; 
      }
      
    }
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 70%;
    }
    &.yy {
      width: 30%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 34%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 32px;
      }
      .nbx {
        flex: 1;
        overflow: hidden;
        font-size: 26px;   
        color: rgb(93, 112, 199);
        text-align: left;
        flex:0 0 auto;
        &.t {
          width: 100%;
        }
        &.w {
          width: 20%;
        }
        &.h1 {
          margin-left: 30px;
        }
        &.h2 {
          margin-left: 10px;
          //text-align: right; 
          //margin-right: 30px;        
        }    
        &.h3 {
          margin-left: 100px; 
        }
        &.d {
          margin-top: 30px; 
        }
        .nbx_1 {
          overflow: hidden;
          font-size: 26px;   
          line-height: 60px;
          height: 60px;
          color: rgb(182, 180, 180); 
          &.fo {
            font-size: 30px;
            color: rgb(43, 30, 87);
          }
          &.co {
            color:rgb(219, 55, 14); 
          }  
          .bq {
            color: rgb(139, 138, 138);   
            width: 20%;
          }
          .nr { 
            width: 80%;
            &.z {
              margin-left: 55px; 
            }
            &.z1 {
              margin-left: 30px; 
            }
          }
        }
      }
      .headline {
        font-size: 26px;       
        color: rgb(136, 134, 134);   
        &.h1 {
          margin-right: 10px;
          float: left;
        }
        &.h2 {
          float: right;         
        }
        &.cc {
          color: rgb(255, 255, 255);   
        }
        &.d1 {
          margin-right: 100px;
          margin-left: 30px; 
        }
        &.d2 {
          margin-right: 30px;
        }
        &.d3 {
          margin-left: 10px; 
        }
        &.d5 {
          margin-right: 15px; 
        }
        &.d6 {
          margin-right: 0px; 
        }
        &.xz {
          color: rgb(14, 177, 206);   
        }
        &.cur {
         cursor: pointer;
        }
        .datespace {
          width: 230px; 
          height: 50px; 
          /* 清除默认边框 */
          border: 0;
          /* 清除默认的箭头样式 */
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: transparent;
          cursor: pointer;  
          &.bt {
            width: 110px; 
          }          
        }
        .rb2 {
          height: 60px;
          width: 126px;
          //float: left;
          overflow: hidden;
          background: rgb(130, 189, 217);
          margin-top: 16px;
          margin-left: 10px;
          border-radius: 15px;
          .icon {
            width: 30px;
            height: 30px;
            margin: 14px 5px 14px 13px;
            display: block;
            float: left;
          }        
          .text {
            float: left;
            color: #fff;
            font-size: 30px;
            line-height: 60px;
          }
        }
      }
      .imgpic {
        display: block;
        width: 114px;   
        height: 160px;  
        float: right;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.tj {
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  display: flex;
  line-height: 50px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;  
  cursor: pointer;
  &.primary {
    //background: linear-gradient(90deg, rgba(98, 226, 230, 1) 0%, rgba(18, 129, 132, 1) 100%);  
    color: rgb(0, 0, 0);
    background: rgb(246, 241, 250);
    margin-bottom: 20px;
  }
  
  &.primary0 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(240, 118, 48);
    color: #fff;
    float: right;
  }
  
  &.primary1 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(114, 191, 214);
    color: #fff;
    float: right;
  }
  &.primary2 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(226, 133, 176);
    color: #fff;
    float: right;
  }
  &.primary3 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(59, 164, 211);
    color: #fff;
    float: right;
  }
  &.primary4 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(245, 179, 74);
    color: #fff;
    float: right;
  }
  &.primary5 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);    
    background: rgb(161, 132, 207);
    color: #fff;
    float: right;
  }
  &.primary6 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(181, 204, 80);
    color: #fff;
    float: right;
  }
   &.primary7 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(124, 167, 223);
    color: #fff;
    float: right;
  }
  &.primary8 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(243, 134, 139);
    color: #fff;
  }
  &.primary9 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(100, 120, 212);
    color: #fff;
  }

  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
  &.rr {
    margin-right: 20px;
  }
  .nbx {
    flex: 1;
    overflow: hidden;
    font-size: 26px;   
    color: rgb(93, 112, 199);
    text-align: left;
    flex:0 0 auto;
    &.t {
      width: 80%;
    }
    &.w {
      width: 20%;
    }
    &.h1 {
      margin-left: 30px;
    }
    &.h2 {
      margin-left: 10px;
      //text-align: right; 
      //margin-right: 30px;        
    }    
    &.h3 {
      margin-left: 100px; 
    }
    &.d {
      margin-top: 30px; 
    }
    .nbx_1 {
      overflow: hidden;
      font-size: 23px;   
      color: rgb(182, 180, 180); 
      &.fo {
        font-size: 30px;
        color: rgb(43, 30, 87);
      }
      &.co {
        color:rgb(219, 55, 14); 
      }  
      .bq {
        color: rgb(139, 138, 138);   
        width: 200px;
      }
    }
  }
}

.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}

.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}

</style>
